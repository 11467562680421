import { Button, Flex, Table } from 'antd';
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { EditOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Meal } from '../../../services/meal.service';
import dayjs from 'dayjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMealService } from '../../../services/types.service';

interface Props {
    meals: Array<Meal>;
}

interface FormValues {
    id: number;
    phone: string;
    firstName: string;
    lastName: string;
}

function getColumns(onEdit: (id: number) => void, onClosedToggled: (id: number) => void): ColumnsType<Meal> {
    return [
        { title: 'Название', dataIndex: 'name', key: 'name' },
        {
            title: 'Дата регистрации',
            dataIndex: 'date',
            key: 'date',
            width: '60%',
            render: (value) => {
                console.dir(value);
                return dayjs(value).format('L');
            }
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            width: '5%',
            align: 'center',
            render: (value) => <Flex>
                <Button type="text"
                        shape="circle"
                        onClick={() => onClosedToggled(value.id)}
                        icon={value.closed ? <LockOutlined/> : <UnlockOutlined/>}/>
                <Button
                    type="text"
                    shape="circle"
                    onClick={() => onEdit(value.id)}
                    icon={<EditOutlined/>}/>
            </Flex>
        }
    ];
}

export default function MealsList({ meals }: Props) {
    const navigate = useNavigate();
    const mealService = useMealService();
    const queryClient = useQueryClient();

    const toggleMealClosedMutation = useMutation({
        mutationFn: async (mealId: number) => {
            await mealService.toggleMealClosed(mealId);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['meals'] });
        }
    });

    return <Table
        columns={getColumns(id => {
            navigate(`${id}`);
        }, (id) => toggleMealClosedMutation.mutate(id))}
        rowKey={meal => meal.id}
        dataSource={meals}
        scroll={{
            x: true
        }}/>;
}
